
import React, {useState, useEffect, useRef} from "react";
import {Button, Modal, Spinner, Toast} from 'react-bootstrap';
import axios from "axios";
import { Stage, Layer, Star, Text, Circle, Image as CanvasImage, Line } from 'react-konva';

import {getUrl} from "../../helper/urlHelper";

import Lower from "../Images/LowerImage";
import Confirmation from "./Confirmation";

function FullscreenModal(props){
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("An error occured, try again.")
  const [errorBg, setErrorBg] = useState("danger")
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const stageRef = useRef(null);
  const width = props.width;
  const height = props.height;

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  let {setValues, values, setCount, count, setModified, modified, initialValues, setInitialValues} = props;  

  const [lines, setLines] = useState(reduceLines(values));
  const [circles, setCircles] = useState(reduceCircles(values));

  function reduceCircles(values){
    let obj = {};

    for (let i = 0; i < 14; i++){
      let key = "d" + (i + 1);

      obj[key] = [
        values[key + "x"],
        values[key + "y"]
      ];
    }

    for (let i = 0; i < 14; i++){
      let key = "m" + (i + 1);

      obj[key] = [
        values[key + "x"],
        values[key + "y"]
      ];
    }

    for (let i = 0; i < 3; i++){
      let key = "l" + (i + 4);

      obj[key] = [
        values[key + "x"],
        values[key + "y"]
      ];
    }

    for (let i = 0; i < 3; i++){
      let key = "r" + (i + 4);

      obj[key] = [
        values[key + "x"],
        values[key + "y"]
      ];
    }    

    obj["c"] = [
      values["cx"],
      values["cy"]
    ]

    return obj
  }

  function reduceLines(values){
    let obj = {};

    for (let i = 0; i < 14; i++){
      let key = "dm" + (i + 1);

      obj[key] = [
        values["d" + (i + 1) + "x"],
        values["d" + (i + 1) + "y"],
        values["m" + (i + 1) + "x"],
        values["m" + (i + 1) + "y"],
      ]
    }

    return obj;
  }

  const handleDrag = (e) => {
    handleLine(e.target.id(), e.target.x(), e.target.y());
    setCount(++count);
    setModified(false);
  }

  function handleToolTip(e){
    //console.log(stageRef)
  }

  function handleLine(id, x, y){
    let chars = id.split("");

    if (chars.length == 3){
      chars[1] = chars[1] + chars[2];
    }

    if (chars[0] === "d" || chars[0] === "m"){
      var newV = lines["dm" + chars[1]];

      setValues({...values, [chars[0] + chars[1] + "x"]: x, [chars[0] + chars[1] + "y"]: y});

      setCircles({...circles, [id]: [x, y]});

      if (chars[0] === "d"){
        newV[0] = x;
        newV[1] = y;
      } else {
        newV[2] = x;
        newV[3] = y;
      }

      setLines({...lines, ["dm" + chars[1]]: newV});
    } else if (chars[0] === "c"){
      setValues({...values, [chars[0] + "x"]: x, [chars[0] + "y"]: y});

      setCircles({...circles, [id]: [x, y]});
    } else {
      setValues({...values, [chars[0] + chars[1] + "x"]: x, [chars[0] + chars[1] + "y"]: y});

      setCircles({...circles, [id]: [x, y]});
    }
  }

  function updatePoints(e){
    e.preventDefault();
    
    setLoading(true)

    axios.post(`${getUrl()}/updateLowerPoints`, props.values)
    .then(function(response){
      setLoading(false)
      setShow(true)
      setErrorBg("success")
      setMessage(response.data);
      setModified(true);
    })
    .catch(function(error){
      console.log(error);
      setLoading(false)
      setShow(true)
      setMessage(error.response.data);
    }).finally(() => {
      setLoading(false);
      setShow(true);
    });
  }

  function handleHide(){
    if (count && modified){
      setInitialValues({...values});
      props.onHide(false);
    } else if (count){
      setConfirm(true);
      setCount(++count);
    } else {
      props.onHide(false)
      setCount(0);
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered   
      backdrop="static"
      keyboard={false}
      fullscreen={true}
    >
      <Modal.Body>
        <div className="canvas-bg">
          {confirm && <Confirmation 
            setConfirm={setConfirm}
            setLines={setLines}
            setCircles={setCircles}
            setValues={setValues}
            values={values}
            setInitialValues={setInitialValues}
            initialValues={initialValues}
            reduceLines={reduceLines}
            reduceCircles={reduceCircles}
            loading={loading}
            setShow={setShow}
            setErrorBg={setErrorBg}
            errorBg={errorBg}
            message={message}
            show={show}
            modified={modified}
            setMessage={setMessage}
            updatePoints={updatePoints}
            count={count}
            setCount={setCount}
            onHide={() => props.onHide(false)}
          />}
          <Stage ref={stageRef} width={width} height={height} style={{zIndex: 5}}>
            <Layer>
              <CanvasImage image={props.image}/>
              {Object.keys(lines).map((line, i) => (
                <Line
                  points={lines[line]}
                  tension={0.2}
                  stroke="red"
                  width={0.2}
                />
              ))}
              {Object.keys(circles).map((circle, i) => {
               return <>
                  <Circle
                    key={i}
                    id={circle}
                    x={circles[circle][0]}
                    y={circles[circle][1]}
                    radius={5}
                    numPoints={5}
                    innerRadius={20}
                    outerRadius={40}
                    fill="white"
                    opacity={1}
                    draggable
                    tooltipText={circle}

                    //rotation={star.rotation}
                    shadowColor="black"
                    shadowBlur={10}
                    shadowOpacity={0.6}
                    onMouseOver={handleToolTip}
                    //shadowOffsetX={star.isDragging ? 10 : 5}
                    //shadowOffsetY={star.isDragging ? 10 : 5}
                    //scaleX={star.isDragging ? 1.2 : 1}
                    //scaleY={star.isDragging ? 1.2 : 1}
                    onDragMove={handleDrag}
                    //onDragStart={handleDragStart}
                    //onDragEnd={handleDragEnd}
                  />
                </>
              })}
            </Layer> 
          </Stage>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!confirm && <Toast bg={errorBg} onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Body>{message}</Toast.Body>
        </Toast>}

        <Button variant="info" onClick={updatePoints} disabled={loading}>
          {loading && !confirm ? <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          /> : <span>Save</span>}</Button>
        <Button variant="danger" onClick={handleHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function App({caseId}){
  const [initialValues, setInitialValues] = useState({});
  const [values, setValues] = useState({});
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [count, setCount] = useState(0);
  const [image, setImage] = useState(null);
  const [modified, setModified] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [type, setType] = useState(false)

  function mouseOver(){
    setType(true);
  }

  function mouseOut(){
    setType(false);
  }

  useEffect(() => {
    axios.get(`${getUrl()}/getLowerPoints/${caseId}`)
    .then(function (response){
      setValues(response.data);
      setInitialValues(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });

    const getMeta = (url, cb) => {
      const img = new Image();
      img.onload = () => cb(null, img);
      img.onerror = (err) => cb(err);
      img.src = url;
    };

    getMeta(`${getUrl()}/getFile/${caseId}/lowerpng`, (err, img) => {
      setWidth(img.naturalWidth);
      setHeight(img.naturalHeight);

      setImage(img);
    });

    return () => {
      setInitialValues({});
      setValues({});
      setCount(0);
      setModified(false);
      setImage(null);
      setWidth(0);
      setHeight(0)
    }
  }, [caseId]);

  if (!height && !width){
    return (
      <div className="images-inner" id="imgBg">
        <Spinner className="spinner" animation="border" />
      </div>      
    )
  }

  return (
    <>
      <div className="images-inner" onClick={() => setModalShow(true)} onMouseOver={() => mouseOver()} onMouseOut={() => mouseOut()}>
        {type && <div className="hover-image">Click To View</div>}
        {modified && <div className="modified"><p>MODIFIED</p></div>}
        <Lower
          width={450} 
          height={400} 
          modified={modified}
          originalWidth={width} 
          originalHeight={height} 
          count={count} 
          values={values} 
          image={image}
        />
      </div>

      <FullscreenModal
        show={modalShow}
        onHide={setModalShow}
        values={values}
        setValues={setValues}
        width={width}
        height={height}
        image={image} 
        count={count}
        setCount={setCount}
        setModified={setModified}
        modified={modified}
        initialValues={initialValues}
        setInitialValues={setInitialValues}
      />
    </>
  );
}

export default App;



import React, {useState, useEffect, createRef} from "react";
import axios from "axios";
import {useParams, useNavigate} from "react-router-dom";
import {
  Accordion,
  ListGroup, 
  Badge, 
  Form, 
  Button, 
  Spinner,
  Card,
  Table,
  Row,
  Col
} from "react-bootstrap";
import {AiOutlineExclamationCircle, AiOutlinePlusCircle} from "react-icons/ai";
import ReactToPdf from "react-to-pdf";

import PatientDetails from "../components/treatment-plans/PatientDetails";

import {getUrl} from "../helper/urlHelper";
import {setFlag} from "../helper/flagHelper";

function Case(){
  const {case_id} = useParams();
  const ref = createRef();
  const navigate = useNavigate();

  useEffect(() => {
    setFlag("treatment-plan");
  }, []);

  //return <Spinner className="spinner" animation="border" />;

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-home">
              {/* logo */}
              {/* <Card.Header>
                <div className="brand-logo" style={{padding: "2px 5px"}}>
                  <span className="rabie">RABIE</span><span className="ai">AI</span>
                </div>
              </Card.Header> */}
              <Card.Body style={{overflow: "hidden"}}>
                <Card className="patient-record-card">

                  <ListGroup variant="flush" className="patient-record-title">
                    <ListGroup.Item><h3 className="patient-record-heading">Patient Records</h3></ListGroup.Item>
                  </ListGroup>

                  <ListGroup as="ol" variant="flush" className="patient-record-sub">               

                    <PatientDetails case_id={case_id}/>

                  </ListGroup>
                </Card>
                
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Case;



import React, {useEffect} from "react";
import {HashRouter, Routes, Route, useNavigate} from "react-router-dom";

import '@progress/kendo-theme-default/dist/all.css';
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/paper-dashboard.css";

import Main from "./layouts/Main.js";
import Case from "./views/Case";
import TreatmentPlan from "./views/TreatmentPlan";
import Home from "./views/Home";
import Login from "./views/Login";

export default function(){

  return <HashRouter>
    <Routes>
      <Route path="/" element={<Main/>}>
        <Route path="" element={<Home/>}/>
        <Route path="case/:case_id" element={<Case/>}/>
        <Route path="treatment-plan/:case_id" element={<TreatmentPlan/>}/>
      </Route>
      <Route path="login" element={<Login/>}/>
    </Routes>
  </HashRouter>
}

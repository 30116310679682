
import react, {useEffect} from "react";
import {Outlet, useNavigate, useLocation} from "react-router-dom";
import {Container, Navbar, Nav} from "react-bootstrap";

import {setFlag} from "../helper/flagHelper";
import {getUser, logout} from "../helper/user-helper";
import {setPathname} from "../helper/urlHelper";

export default function(){
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let user = getUser();

    if (!user){
      setPathname(location.pathname);
      navigate("login");
    }
  }, [])

  function handleClick(e){
    e.preventDefault();
    setFlag("home");
    window.location.replace(window.location.origin);
  }

  function handleLogout(){
    logout();
    navigate("login");
  }

  return (
    <div>    
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand onClick={handleClick} className="nav-brand">
            <div className="brand-logo">
              <span className="rabie">RABIE</span><span className="ai">AI</span>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <button type="button" className="btn btn-outline-info" onClick={handleLogout}>LOG OUT</button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container> 
        <div className="main-container">
          <Outlet/>
        </div>
      </Container>
    </div>
  )
}


/*
      <Navbar bg="dark">  
        <Container>  
          <Navbar.Brand onClick={handleClick} className="nav-brand">
            <div className="brand-logo">
              <span className="rabie">RABIE</span><span className="ai">AI</span>
            </div>
          </Navbar.Brand>  
        </Container>  
      </Navbar>*/


function getUrl(){
  // return "http://127.0.0.1:5000"
  // return "https://cloudorthowebservertko2.ap-northeast-1.elasticbeanstalk.com"
  return "https://server.cloudortho.ai"
}

function getTokenUrl(){
  // return "https://ygkqspqxrsbqo5nnpkav6fkglq0maowh.lambda-url.ap-northeast-1.on.aws/";
  // return "https://jc462q94d9.execute-api.ap-east-1.amazonaws.com/latest";
  return "https://api.cloudortho.ai";
  // return "https://otzkrzcd6h.execute-api.us-east-1.amazonaws.com/latest";
}

function getPathname(){
  return JSON.parse(localStorage.getItem("pathname"));
}

function setPathname(pathname){
  localStorage.setItem("pathname", JSON.stringify(pathname));
}

export {getUrl, getPathname, setPathname, getTokenUrl};

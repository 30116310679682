
import React, {useState, useRef, useEffect} from "react";
import { Stage, Layer, Star, Text, Circle, Image as CanvasImage, Line } from 'react-konva';

export default function({values, image, width, height, originalWidth, originalHeight, count, modified}){

  const [lines, setLines] = useState(reduceLines(values));
  const [circles, setCircles] = useState(reduceCircles(values));

  function scaleWidth(w){
    return (w * width) / originalWidth;
  }

  function scaleHeight(h){
    return (h * height) / originalHeight;
  }

  function reduceCircles(values){
    let obj = {};

    obj.mxp = [scaleWidth(values.mxpx), scaleHeight(values.mxpy)];
    obj.mxa = [scaleWidth(values.mxax), scaleHeight(values.mxay)];
    obj.ur = [scaleWidth(values.urx), scaleHeight(values.ury)];
    obj.ut = [scaleWidth(values.utx), scaleHeight(values.uty)];
    obj.mnp = [scaleWidth(values.mnpx), scaleHeight(values.mnpy)];
    obj.mna = [scaleWidth(values.mnax), scaleHeight(values.mnay)];
    obj.lt = [scaleWidth(values.ltx), scaleHeight(values.lty)];
    obj.lr = [scaleWidth(values.lrx), scaleHeight(values.lry)];

    return obj
  }

  function reduceLines(values){
    let obj = {};

    obj.mx = [
      scaleWidth(values.mxpx), 
      scaleHeight(values.mxpy), 
      scaleWidth(values.mxax), 
      scaleHeight(values.mxay)
    ];
    obj.u = [
      scaleWidth(values.urx), 
      scaleHeight(values.ury), 
      scaleWidth(values.utx), 
      scaleHeight(values.uty)
    ];
    obj.mn = [
      scaleWidth(values.mnpx), 
      scaleHeight(values.mnpy), 
      scaleWidth(values.mnax), 
      scaleHeight(values.mnay)
    ];
    obj.l = [
      scaleWidth(values.ltx), 
      scaleHeight(values.lty), 
      scaleWidth(values.lrx), 
      scaleHeight(values.lry)
    ];

    return obj;
  }

  useEffect(() => {
    setLines(reduceLines(values));
    setCircles(reduceCircles(values));
  }, [count]);

  return (
    <Stage width={width} height={height} style={{zIndex: 5}}>
      <Layer>
        <CanvasImage image={image} width={width} height={height}/>
        {Object.keys(lines).map((line, i) => (
          <Line
            points={lines[line]}
            tension={0.2}
            stroke="red"
            width={0.2}
          />
        ))}
        {Object.keys(circles).map((circle, i) => (
          <>
            <Circle
              key={i}
              id={circle}
              x={circles[circle][0]}
              y={circles[circle][1]}
              radius={3}
              numPoints={5}
              innerRadius={20}
              outerRadius={40}
              fill="white"
              opacity={1}
              shadowColor="black"
              shadowBlur={10}
              shadowOpacity={0.6}
              onMouseOver={() => {}}
            />
          </>
        ))}
      </Layer>
    </Stage>
  )
}

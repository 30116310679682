
import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {Spinner} from "react-bootstrap";
import { Stage, Layer, Star, Text, Circle, Image as CanvasImage, Line } from 'react-konva';

import {getUrl} from "../../helper/urlHelper";

export default function({url}){

  const {case_id} = useParams();
  const [image, setImage] = useState(null);
  const [width, setWidth] = useState(418.5);
  const [height, setHeight] = useState(400);

  useEffect(() => {
    const getMeta = (url, cb) => {
      const img = new Image();
      img.onload = () => cb(null, img);
      img.onerror = (err) => cb(err);
      img.src = url;
    };

    getMeta(`${getUrl()}/getFile/${case_id}/${url}`, (err, img) => {
      setImage(img);

      let elem = document.getElementById(url);
      elem.appendChild(img)
    });
  }, []);

  return (
    <div className="images-inner" id="imgBg">
      <div id={url} style={{width: "100%", height: "100%"}}>
        {!image && <Spinner className="spinner" animation="border" />}
      </div>
    </div>
  )
}

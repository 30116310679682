
import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useDropzone} from 'react-dropzone';
import {AiOutlineUpload} from "react-icons/ai";
import {Spinner, Toast, Form} from "react-bootstrap";
import axios from "axios";
import DatePicker from "react-date-picker";
import {RadioGroup, RadioButton} from 'react-radio-buttons';

import {getUrl, getTokenUrl} from "../../helper/urlHelper";
import {getUser} from "../../helper/user-helper";

import XrayDrop from "../../components/Dropzones/XrayDrop";
import UpperDrop from "../../components/Dropzones/UpperDrop";
import LowerDrop from "../../components/Dropzones/LowerDrop";
import Drop from "../../components/Dropzones/Drop";

import Xray from "../../assets/xray-button.png";
import XrayCross from "../../assets/xray-button-with-cross.png";
import Lower from "../../assets/lower-button.png";
import LowerCross from "../../assets/lower-button-with-cross.png";
import Upper from "../../assets/upper-button.png";
import UpperCross from "../../assets/upper-button-with-cross.png";

import LateralCeph from "../../assets/lateralCeph.png";
import FrontalPhoto from "../../assets/frontalPhoto.png";
import SmilePhoto from "../../assets/smilePhoto.png";
import LateralPhoto from "../../assets/lateralPhoto.png";
import RightIntraoral from "../../assets/rightIntraOral.png";
import FrontalIntraoral from "../../assets/frontalIntraOral.png";
import LeftIntraoral from "../../assets/leftIntraOral.png";
import UpperOcclusal from "../../assets/upperOcclusal.png";
import LowerOcclusal from "../../assets/lowerOcclusal.png";
import Pan from "../../assets/pan.png";

export default function UploadForm(){
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("An error occured, try again.")
  const [errorBg, setErrorBg] = useState("danger")
  const [images, setImages] = useState({
    lateralCeph: null,
    upperstl: null,
    lowerstl: null,
    frontalPhoto: null,
    smilePhoto: null,
    lateralPhoto: null,
    rightIntraoral: null,
    frontalIntraoral: null,
    leftIntraoral: null,
    upperOcclusal: null,
    lowerOcclusal: null,
    pan: null
  });
  const [values, setValues] = useState({
    name: "",
    birthday: "",
    gender: "",
    ethnicity: "CAUCASIAN",
    distalizationLeft: "class1",
    distalizationRight: "class1",
    token: getUser() ? getUser().token : ""
  })
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [XrayPic, setXrayPic] = useState(Xray);
  const [UpperPic, setUpperPic] = useState(Upper);
  const [LowerPic, setLowerPic] = useState(Lower);
  const [currentDrop, setCurrentDrop] = useState("");
  const [loading, setLoading] = useState(false);
  
  const [lateralCephError, setLateralCephError] = useState({error: false, errorMessage: ""})
  const [upperstlError, setUpperstlError] = useState({error: false, errorMessage: ""})
  const [lowerstlError, setLowerstlError] = useState({error: false, errorMessage: ""})
  const [frontalPhotoError, setFrontalPhotoError] = useState({error: false, errorMessage: ""})
  const [smilePhotoError, setSmilePhotoError] = useState({error: false, errorMessage: ""})
  const [lateralPhotoError, setLateralPhotoError] = useState({error: false, errorMessage: ""})
  const [rightIntraoralError, setRightIntraoralError] = useState({error: false, errorMessage: ""})
  const [frontalIntraoralError, setFrontalIntraoralError] = useState({error: false, errorMessage: ""})
  const [leftIntraoralError, setLeftIntraoralError] = useState({error: false, errorMessage: ""})
  const [upperOcclusalError, setUpperOcclusalError] = useState({error: false, errorMessage: ""})
  const [lowerOcclusalError, setLowerOcclusalError] = useState({error: false, errorMessage: ""})
  const [panError, setPanError] = useState({error: false, errorMessage: ""})
  
  const [nameError, setNameError] = useState({error: false, errorMessage: ""})
  const [dateOfBirthError, setDateOfBirthError] = useState({error: false, errorMessage: ""})
  const [genderError, setGenderError] = useState({error: false, errorMessage: ""})
  const [ethnicityError, setEthnicityError] = useState({error: false, errorMessage: ""})
  const [tokenError, setTokenError] = useState({error: false, errorMessage: ""})

  const [tokenLoader, setTokenLoader] = useState(false)

  const [defaultToken, setDefaultToken] = useState("")
  const pollInterval = 2000; // 2 seconds
  const pollAttempts = 60;

  function validate(){
    let valid = true;

    let errs = Object.keys(images)
    let name = "";

    if (!values.name.trim()){
      valid = false;
      setNameError({
        error: true,
        errorMessage: "Name is required"
      })
    }

    if (!values.birthday.trim()){
      valid = false;
      setDateOfBirthError({
        error: true,
        errorMessage: "Date Of Birth is required"
      })
    }

    if (!values.gender.trim()){
      valid = false;
      setGenderError({
        error: true,
        errorMessage: "Gender is required"
      })
    }    

    if (!values.ethnicity){
      valid = false;
      setEthnicityError({
        error: true,
        errorMessage: "Ethnicity is required"
      })
    }

    if (!values.token.trim()){
      valid = false;
      setTokenError({
        error: true,
        errorMessage: "Token is required"
      })
    }

    if (images.lateralCeph){

      let size = images.lateralCeph.size / 1000000;

      if (size < 52){
        setLateralCephError({error: false, errorMessage: ""});

      } else {
        setLateralCephError({error: true, errorMessage: "File too large"});

        valid = false;
      }
    } else {
      setLateralCephError({error: true, errorMessage: "Please select a file"});

      valid = false;
    }

    if (images.upperstl){

      let size = images.upperstl.size / 1000000;

      if (size < 52){
        setUpperstlError({error: false, errorMessage: ""});

      } else {
        setUpperstlError({error: true, errorMessage: "File too large"});

        valid = false;
      }
    } else {
      setUpperstlError({error: true, errorMessage: "Please select a file"});

      valid = false;
    }

    if (images.upperstl){

      let size = images.upperstl.size / 1000000;

      if (size < 52){
        setLowerstlError({error: false, errorMessage: ""});

      } else {
        setLowerstlError({error: true, errorMessage: "File too large"});

        valid = false;
      }
    } else {
      setLowerstlError({error: true, errorMessage: "Please select a file"});

      valid = false;
    }

    if (images.frontalPhoto){

      let size = images.frontalPhoto.size / 1000000;

      if (size < 52){
        setFrontalPhotoError({error: false, errorMessage: ""});

      } else {
        setFrontalPhotoError({error: true, errorMessage: "File too large"});

        valid = false;
      }
    } else {
      setFrontalPhotoError({error: true, errorMessage: "Please select a file"});

      valid = false;
    }

    if (images.smilePhoto){

      let size = images.smilePhoto.size / 1000000;

      if (size < 52){
        setSmilePhotoError({error: false, errorMessage: ""});

      } else {
        setSmilePhotoError({error: true, errorMessage: "File too large"});

        valid = false;
      }
    } else {
      setSmilePhotoError({error: true, errorMessage: "Please select a file"});

      valid = false;
    }

    if (images.lateralPhoto){

      let size = images.lateralPhoto.size / 1000000;

      if (size < 52){
        setLateralPhotoError({error: false, errorMessage: ""});

      } else {
        setLateralPhotoError({error: true, errorMessage: "File too large"});

        valid = false;
      }
    } else {
      setLateralPhotoError({error: true, errorMessage: "Please select a file"});

      valid = false;
    }

    if (images.rightIntraoral){

      let size = images.upperstl.size / 1000000;

      if (size < 52){
        setRightIntraoralError({error: false, errorMessage: ""});

      } else {
        setRightIntraoralError({error: true, errorMessage: "File too large"});

        valid = false;
      }
    } else {
      setRightIntraoralError({error: true, errorMessage: "Please select a file"});

      valid = false;
    }

    if (images.frontalIntraoral){

      let size = images.frontalIntraoral.size / 1000000;

      if (size < 52){
        setFrontalIntraoralError({error: false, errorMessage: ""});

      } else {
        setFrontalIntraoralError({error: true, errorMessage: "File too large"});

        valid = false;
      }
    } else {
      setFrontalIntraoralError({error: true, errorMessage: "Please select a file"});

      valid = false;
    }

    if (images.leftIntraoral){

      let size = images.leftIntraoral.size / 1000000;

      if (size < 52){
        setLeftIntraoralError({error: false, errorMessage: ""});

      } else {
        setLeftIntraoralError({error: true, errorMessage: "File too large"});

        valid = false;
      }
    } else {
      setLeftIntraoralError({error: true, errorMessage: "Please select a file"});

      valid = false;
    }

    if (images.upperOcclusal){

      let size = images.upperOcclusal.size / 1000000;

      if (size < 52){
        setUpperOcclusalError({error: false, errorMessage: ""});

      } else {
        setUpperOcclusalError({error: true, errorMessage: "File too large"});

        valid = false;
      }
    } else {
      setUpperOcclusalError({error: true, errorMessage: "Please select a file"});

      valid = false;
    }

    if (images.lowerOcclusal){

      let size = images.lowerOcclusal.size / 1000000;

      if (size < 52){
        setLowerOcclusalError({error: false, errorMessage: ""});

      } else {
        setLowerOcclusalError({error: true, errorMessage: "File too large"});

        valid = false;
      }
    } else {
      setLowerOcclusalError({error: true, errorMessage: "Please select a file"});

      valid = false;
    }

    if (images.pan){

      let size = images.pan.size / 1000000;

      if (size < 52){
        setPanError({error: false, errorMessage: ""});

      } else {
        setPanError({error: true, errorMessage: "File too large"});

        valid = false;
      }
    } else {
      setPanError({error: true, errorMessage: "Please select a file"});

      valid = false;
    }

    if (!valid){
      setMessage("Missing required fields. Please check again.");
      setShow(true);

      let elem = document.getElementById("form");
      elem.scrollIntoView();
    }

    return valid;
  }

  function handleSubmit(e){
    e.preventDefault();
    
    let valid = validate();
    
    if (valid){
      let caseData = new FormData();
      images.lateralCeph && caseData.append("lateralCeph", images.lateralCeph);
      images.upperstl && caseData.append("upperstl", images.upperstl);
      images.lowerstl && caseData.append("lowerstl", images.lowerstl);
      images.frontalPhoto && caseData.append("frontalPhoto", images.frontalPhoto);
      images.smilePhoto && caseData.append("smilePhoto", images.smilePhoto);
      images.lateralPhoto && caseData.append("lateralPhoto", images.lateralPhoto);
      images.rightIntraoral && caseData.append("rightIntraoral", images.rightIntraoral);
      images.frontalIntraoral && caseData.append("frontalIntraoral", images.frontalIntraoral);
      images.leftIntraoral && caseData.append("leftIntraoral", images.leftIntraoral);
      images.upperOcclusal && caseData.append("upperOcclusal", images.upperOcclusal);
      images.lowerOcclusal && caseData.append("lowerOcclusal", images.lowerOcclusal);
      images.pan && caseData.append("pan", images.pan);
      
      caseData.append("name", values.name);
      caseData.append("birthday", values.birthday);
      caseData.append("gender", values.gender);
      caseData.append("ethnicity", values.ethnicity);
      caseData.append("distalizationLeft", values.distalizationLeft);
      caseData.append("distalizationRight", values.distalizationRight);
      caseData.append("token", values.token);

      setLoading(true);

      axios.post(`${getUrl()}/uploadCase`, caseData, { timeout: 300000 })
      .then(function (response) {
        const caseId = response.data.caseId;
        pollForLowerPoints(caseId, 1);
      })
      .catch(function (error) {
        setShow(true);
        console.log(error);
        if (error.data){
          setMessage(error.data);
        } else if (error.response.data) {
          setMessage(error.response.data);
        } else {
          setMessage("Error Occured. Try again.")
        }
        setLoading(false)
      });
    }
  }

  function pollForLowerPoints(caseId, attempt) {
    axios.get(`${getUrl()}/getLowerPoints/${caseId}`)
      .then((response) => {
        setShow(true);
        setErrorBg("success");
        setMessage(`Case successfully created with case id "${caseId}"`);
        navigate("/case/" + caseId);
        setLoading(false);
      })
      .catch((error) => {
        if (attempt < pollAttempts) {
          setTimeout(() => {
            pollForLowerPoints(caseId, attempt + 1);
          }, pollInterval);
        } else {
          setShow(true);
          console.log(error);
          if (error.data) {
            setMessage(error.data);
          } else if (error.response.data) {
            setMessage(error.response.data);
          } else {
            setMessage("Error Occured. Try again.")
          }
          setLoading(false);
        }
      });
  }

  function handleDate(date){
    let day = String(date.getDate()).length > 1 ? date.getDate() : `0${date.getDate()}`;
    let month = String(date.getMonth()).length > 1 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;

    let d = `${day}/${month}/${date.getFullYear()}`;
    setValues({...values, birthday: d});
    setDateOfBirth(date);
    clearError("dateOfBirth");
  }

  function handleChange(e){
    let name = e.target.name;

    setValues({...values, [name]: e.target.value})
    clearError(name);
  }

  function clearError(name){
    switch (name){
      case "dateOfBirth":
        setDateOfBirthError({error: false, errorMessage: ""});
        break;

      case "name":
        setNameError({error: false, errorMessage: ""})
        break;

      case "ethnicity":
        setEthnicityError({error: false, errorMessage: ""});
        break;

      case "token":
        setTokenError({error: false, errorMessage: ""});
        break;
    }
  }

  function verifyToken(e){
    setTokenLoader(true);

    axios.post(`${getTokenUrl()}/verifyToken`, {
      token: e.target.value
    })
    .then(function (response) {
      if (response.data.status === "NEW"){
        setValues({...values, token: response.data.token})
      } else {
        setTokenError({
          error: true,
          errorMessage: response.data
        })
      }
    })
    .catch(function (error) {
      setTokenError({
        error: true,
        errorMessage: "Error occured"
      })
    })
    .finally(function(){
      setTokenLoader(false);
    })
  }

  return (
    <div>
      <form onSubmit={handleSubmit} className="form-container">
        {loading && <div className="spinner-container">
          <Spinner className="spinner" animation="border" />
        </div>}

        {/* <div className="upload-form d-flex justify-content-center" id="form">
          <h4>Create Case</h4>
        </div> */}

        <div className="upload-form">
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control 
              type="text" 
              style={{border: nameError.error ? "1px solid red" : ""}}
              placeholder="Enter name" 
              onChange={handleChange}
              name="name"
            />
            {nameError.error && <p className="error-message">{nameError.errorMessage}</p>}
          </Form.Group>

          <Form.Group className="mb-3 token-input-container">
            {tokenLoader && <div className="token-input-loader"><Spinner animation="border" size="sm"/></div>}
            <Form.Label>Token</Form.Label>
            <Form.Control 
              type="text" 
              style={{border: tokenError.error ? "1px solid red" : ""}}
              placeholder="Enter Token"
              onBlur={verifyToken}
              defaultValue={values.token}
              name="token"
            />
            {tokenError.error && <p className="error-message">{tokenError.errorMessage}</p>}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Date Of Birth</Form.Label>
            <div 
              className="bg-white datepicker-container" 
              style={{border: dateOfBirthError.error ? "1px solid red" : ""}}
            >
              <DatePicker value={dateOfBirth} onChange={handleDate} format={`dd/MM/y`}/>
            </div>
            {dateOfBirthError.error && <p className="error-message">{dateOfBirthError.errorMessage}</p>}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Gender</Form.Label>
            <Form.Control 
              type="text" 
              style={{border: genderError.error ? "1px solid red" : ""}}
              placeholder="Gender" 
              onChange={handleChange}
              name="gender"
            />
            {genderError.error && <p className="error-message">{genderError.errorMessage}</p>}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Ethnicity</Form.Label>
            <div>
              <select 
                className="select"
                style={{border: ethnicityError.error ? "1px solid red" : ""}}
                onChange={handleChange}
                name="ethnicity" 
              >
                <option value="CAUCASIAN">CAUCASIAN</option>
                <option value="CHINESE">CHINESE</option>
              </select>
            </div>
            {ethnicityError.error && <p className="error-message">{ethnicityError.errorMessage}</p>}
          </Form.Group>
          
          <Form.Group className="mb-3">
            <div style={{ display: "flex", gap: "1rem" }}>
              <div style={{ flex: "1" }}>
                <Form.Label>Molar AP Relationship Left Side</Form.Label>
                <select
                  className="select"
                  onChange={handleChange}
                  name="distalizationLeft"
                  style={{ width: "100%" }}
                >
                  <option value="class1">Class I</option>
                  <option value="class2-1">Class II by 1mm</option>
                  <option value="class2-2">Class II by 2mm</option>
                  <option value="class2-3">Class II by 3mm</option>
                </select>
              </div>
              <div style={{ flex: "1" }}>
                <Form.Label>Molar AP Relationship Right Side</Form.Label>
                <select
                  className="select"
                  onChange={handleChange}
                  name="distalizationRight"
                  style={{ width: "100%" }}
                >
                  <option value="class1">Class I</option>
                  <option value="class2-1">Class II by 1mm</option>
                  <option value="class2-2">Class II by 2mm</option>
                  <option value="class2-3">Class II by 3mm</option>
                </select>
              </div>
            </div>
          </Form.Group>

          <Form.Group className="mb-3">
            <p><a href="https://vimeo.com/832913382/c258e90c1e" target="_blank">SUBMISSION INSTRUCTIONS</a></p>
          </Form.Group>

          {/*<button onClick={() => console.log(values)}>Click</button>*/}
        </div>

        <div className="upload-form upload-container">
          <Drop 
            name="lateralCeph"
            title="Lateral Ceph"
            images={images} 
            setImages={setImages}
            error={lateralCephError}
            setError={setLateralCephError}
            pic={LateralCeph}
          />
          <Drop 
            name="upperstl"
            title="Upper"
            images={images} 
            setImages={setImages}
            error={upperstlError}
            setError={setUpperstlError}
            pic={Upper}
          />
          <Drop 
            name="lowerstl"
            title="Lower"
            images={images} 
            setImages={setImages}
            error={lowerstlError}
            setError={setLowerstlError}
            pic={Lower}
          />
        </div>

        <div className="upload-form d-flex">
          <Drop
            name="frontalPhoto"
            title="Frontal Photo" 
            images={images} 
            setImages={setImages}
            error={frontalPhotoError}
            setError={setFrontalPhotoError}
            pic={FrontalPhoto}
          />
          <Drop
            name="smilePhoto"
            title="Smile Photo" 
            images={images} 
            setImages={setImages}
            error={smilePhotoError}
            setError={setSmilePhotoError}
            pic={SmilePhoto}
          />
          <Drop
            name="lateralPhoto"
            title="Lateral Photo" 
            images={images} 
            setImages={setImages}
            error={lateralPhotoError}
            setError={setLateralPhotoError}
            pic={LateralPhoto}
          />
        </div>

        <div className="upload-form d-flex">
          <Drop
            name="rightIntraoral"
            title="Right Intra Oral" 
            images={images} 
            setImages={setImages}
            error={rightIntraoralError}
            setError={setRightIntraoralError}
            pic={RightIntraoral}
          />
          <Drop
            name="frontalIntraoral"
            title="Frontal Intra Oral" 
            images={images} 
            setImages={setImages}
            error={frontalIntraoralError}
            setError={setFrontalIntraoralError}
            pic={FrontalIntraoral}
          />
          <Drop
            name="leftIntraoral"
            title="Left Intra Oral" 
            images={images} 
            setImages={setImages}
            error={leftIntraoralError}
            setError={setLeftIntraoralError}
            pic={LeftIntraoral}
          />
        </div>

        <div className="upload-form d-flex">
          <Drop
            name="upperOcclusal"
            title="Upper Occlusal" 
            images={images} 
            setImages={setImages}
            error={upperOcclusalError}
            setError={setUpperOcclusalError}
            pic={UpperOcclusal}
          />
          <Drop
            name="lowerOcclusal"
            title="Lower Occlusal" 
            images={images} 
            setImages={setImages}
            error={lowerOcclusalError}
            setError={setLowerOcclusalError}
            pic={LowerOcclusal}
          />
          <Drop
            name="pan"
            title="Pan" 
            images={images} 
            setImages={setImages}
            error={panError}
            setError={setPanError}
            pic={Pan}
          />
        </div>

        <div className="upload-form d-flex justify-content-end">
          <Toast bg={errorBg} onClose={() => setShow(false)} show={show}>
            <Toast.Body>{message}</Toast.Body>
          </Toast>
          <button onClick={handleSubmit} className="submit-button ms-4"><AiOutlineUpload size={25}/> SUBMIT CASE</button>
        </div>
      </form>
    </div>
  )
}

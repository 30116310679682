
export function getUser(){
  let admin = JSON.parse(sessionStorage.getItem("admin"));

  if (admin){
    if (admin.token.length <= 20){
      return undefined;
    } else {
      return admin;
    }
  } else {
    return undefined;
  }
}

export function saveUser(user){
  sessionStorage.setItem("admin", JSON.stringify(user));
}

export function logout(){
  sessionStorage.removeItem('admin');
}

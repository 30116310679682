
import React, {useState, useEffect} from "react";

import {useNavigate} from "react-router-dom";
import {
  ListGroup, 
  Badge, 
  Form, 
  Button,
  Card,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col
} from "react-bootstrap";
import {AiOutlineExclamationCircle, AiOutlinePlusCircle} from "react-icons/ai";

import UploadForm from "../components/Form/UploadForm";

import {getFlag, setFlag} from "../helper/flagHelper";

function Home(){
  const navigate = useNavigate();
  const [caseId, setCaseId] = useState("");
  const [caseIdError, setCaseIdError] = useState({
    error: false,
    message: ""
  });

  function onClick(e){
    e.preventDefault();

    let id = caseId.trim();

    if (id){
      navigate("/case/" + id);
    } else {
      setCaseIdError({
        error: true,
        message: "Please type in a valid case ID"
      })
    }
  }

  useEffect(() => {
    setFlag("home");
  }, []);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-home">
              {/* <Card.Header>
                <div className="brand-logo" style={{padding: "2px 5px"}}>
                  <span className="rabie">RABIE</span><span className="ai">AI</span>
                </div>
              </Card.Header> */}
              <Card.Body style={{overflow: "hidden"}}>

                {/* <Card className="patient-record-card">

                  <ListGroup variant="flush" className="patient-record-title">
                    <ListGroup.Item><h3 className="patient-record-heading">Patient Records</h3></ListGroup.Item>
                  </ListGroup>

                  <ListGroup as="ol" variant="flush" className="patient-record-sub">
                  
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                      <div className="me-auto my-2 d-flex">
                        <div className="record-icon-container">
                          <AiOutlineExclamationCircle size={25} color="white" className="record-icon"/>
                        </div>
                        <div>
                          <div className="fw-bold"><h4 className="patient-record-heading">Patient Details</h4></div>
                          Required For Treatment
                        </div>
                      </div>
                      <div className="record-add">
                        <AiOutlinePlusCircle color="blue" size={25} className="record-add-icon"/>
                        <span>ADD</span>
                      </div>
                    </ListGroup.Item>
                    
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                      <div className="my-2 me-auto d-flex">
                        <div className="record-icon-container">
                          <AiOutlineExclamationCircle size={25} color="white" className="record-icon"/>
                        </div>
                        <div>
                          <div className="fw-bold"><h4 className="patient-record-heading">Patient Images, X-Rays</h4></div>
                          Required For Treatment
                        </div>
                      </div>
                      <div className="record-add">
                        <AiOutlinePlusCircle color="blue" size={25} className="record-add-icon"/>
                        <span>ADD</span>
                      </div>
                    </ListGroup.Item>

                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                      <div className="my-2 me-auto d-flex">
                        <div className="record-icon-container">
                          <AiOutlineExclamationCircle size={25} color="white" className="record-icon"/>
                        </div>
                        <div>
                          <div className="fw-bold"><h4 className="patient-record-heading">Patient X-Rays And Scans</h4></div>
                          Required For Treatment
                        </div>
                      </div>
                    </ListGroup.Item>
                    
                    <Card className="case-id-form">
                      <Form onSubmit={onClick}>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Enter Case ID</Form.Label>
                          <Form.Control type="text" placeholder="Case Id" onChange={(e) => setCaseId(e.target.value)}/>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                          Submit
                        </Button>
                      </Form>
                    </Card>

                  </ListGroup>
                </Card> */}

                {/* <Card className="case-id-form">
                  <Form onSubmit={onClick}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Enter Case ID</Form.Label>
                      <Form.Control type="text" placeholder="Case Id" onChange={(e) => {
                        setCaseIdError({
                          error: false,
                          message: ""
                        })
                        setCaseId(e.target.value)
                      }} style={{borderColor: caseIdError.error ? `red` : ""}}/>
                    </Form.Group>
                    {caseIdError.error && <p style={{color: "red"}}>{caseIdError.message}</p>}
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
                </Card> */}

                <Card className="card-home">
                  <UploadForm />
                </Card>

              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Home;


function setFlag(flag){
  localStorage.setItem("flag", flag);
}

function getFlag(){
  return localStorage.getItem("flag");
}

export {setFlag, getFlag}


import axios from "axios";
import React, {useState, useEffect, Component} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Alert, Button, Spinner} from "react-bootstrap";
import short from "short-uuid";
import {AiOutlineCopy} from "react-icons/ai";
import {GiCheckMark} from "react-icons/gi";
import copy from "clipboard-copy";

import {saveUser} from "../helper/user-helper";
import {getPathname, getTokenUrl} from "../helper/urlHelper";

export default function(){
  let [searchParams] = useSearchParams();
  const [token, setToken] = useState("")
  const [paramToken, setParamToken] = useState("")
  const [error, setError] = useState({
    error: false,
    errorMessage: ""
  });

  const [paramLoader, setParamLoader] = useState(false);
  const [tokenLoader, setTokenLoader] = useState(false);
  const [payLoader, setPayLoader] = useState(false);
  const [copyText, setCopyText] = useState(false);

  const navigate = useNavigate();

  function handleChange(e){
    setToken(e.target.value);
  }

  function copyToClipboard(){
    setCopyText(true);
    copy(paramToken);
  }

  function handleSubmit(e){
    e.preventDefault();

    if (token.length <= 20){
      setError({
        error: true,
        errorMessage: "Please enter a valid token"
      })
    } else {
      setTokenLoader(true);
      
      axios.post(`${getTokenUrl()}/verifyToken`, {
        token: token.trim()
      })
      .then(function (response) {
        console.log(response)
        if (response.data.token){
          saveUser(response.data);
          navigate("/")
        } else {
          setError({
            error: true,
            errorMessage: response.data
          })
        }
      })
      .catch(function (error) {
        console.log(error)
        setError({
          error: true,
          errorMessage: error.message
        })
      })
      .finally(function(){
        setTokenLoader(false);
      })
    }
    
  }

  useEffect(() => {
    let tok = searchParams.get("token");
    let fail = searchParams.get("cancel");

    if (fail || fail === 0){
      setParamLoader(true)

      axios.post(`${getTokenUrl()}/deleteToken`, {
        token: fail
      })
      .then(function(response){
        console.log(response);
        setError({
          error: true,
          errorMessage: "Payment Unsuccessful"
        })
      })
      .catch(function(error){
        console.log(error)
        setError({
          error: true,
          errorMessage: error.message
        })
      })
      .finally(function(){
        setParamLoader(false);
      })
    }


    if (tok){
      setParamLoader(true)

      axios.post(`${getTokenUrl()}/createToken`, {
        token: tok
      })
      .then(function (response) {
        console.log(response);
        setParamToken(tok);
      })
      .catch(function (error) {
        console.log(error)
        setError({
          error: true,
          errorMessage: error.message
        })
      })
      .finally(function(){
        setParamLoader(false);
      })
    }
  }, [])

  function getUrl(){
    setPayLoader(true);
    axios.post(`${getTokenUrl()}/checkout`, {
      token: short().new(),
      url: window.location.origin
    })
    .then(function (response) {
      window.location.href = response.data;
    })
    .catch(function (error) {
      console.log(error)
      setError({
        error: true,
        errorMessage: error.message
      })
    })
    .finally(function(){
      setPayLoader(false);
    })
  }

  if (paramLoader){
    return <div className="login-spinner-container">
      <Spinner animation="border" size="lg"/>
    </div>
  }
  
  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="brand-logo">
          <span className="rabie">RABIE</span><span className="ai">AI</span>
        </div>
        <h3>Token</h3>

        {paramToken && <Alert variant="success" className="w-100 mb-1 text-center">
          Payment Success
        </Alert>}

        {paramToken && <div className="copy border-success" onClick={copyToClipboard}>
          <p>{paramToken}</p>
          {copyText ? <div className="copy-icon-container bg-success">
            <GiCheckMark size={20} className="copy-icon"/>
          </div> : <div className="copy-icon-container">
            <AiOutlineCopy size={20} className="copy-icon"/>
          </div>}
        </div>}

        {paramToken && <p className="text-danger token-warning">
          Please copy and keep this token safe
        </p>}

        {error.error && <Alert variant="danger" className="w-100 mb-1 text-center">
          {error.errorMessage}
        </Alert>}

        <div className="mb-3">
          <label>Token</label>
          <input
            type="text"
            name="token"
            className="form-control"
            placeholder="Enter Token"
            onChange={handleChange}
          />
        </div>

        <div className="d-grid">
          <button type="submit" className="btn btn-primary" disabled={tokenLoader}>
            {tokenLoader ? <Spinner animation="border" size="sm"/> : <span>Submit</span>}
          </button>
          <button onClick={getUrl} className="btn btn-secondary" disabled={payLoader}>
            {payLoader ? <Spinner animation="border" size="sm"/> : <span>Pay</span>}
          </button>
        </div>
      </form>
    </div>
  )
}

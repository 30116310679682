
import React, {useState} from "react";
import {useDropzone} from 'react-dropzone';
import {AiOutlineUpload} from "react-icons/ai";
import {Spinner, Toast} from "react-bootstrap";
import axios from "axios";

import {getUrl} from "../../helper/urlHelper";

import XrayCross from "../../assets/xray-button-with-cross.png";

export default function UploadForm({name, title, images, setImages, error, setError, pic}){
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("An error occured, try again.")
  const [errorBg, setErrorBg] = useState("danger")

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    maxFiles: 1,
    accept: {
      'image/*': [".jpg", ".jpeg", ".png", ".stl"]
    },
    onDrop,
    onDropRejected
  });

  function onDrop(files, e, f){
    setImages({...images, [name]: files[0]});
    setError({error: false, errorMessage: ""});
  }

  function onDropRejected(f, e){
    setError({error: true, errorMessage: "Unsupported file type"});
  }

  return (
    <div {...getRootProps({ className: "dropzone text-center ms-3" })}>
      <h3 style={{marginBottom: 0}}>{title}</h3>
      <input {...getInputProps()}/>
      {getImg(name, images, pic)}
      {images[name] && <p className="file_name">{images[name].name}</p>}
      {error.error && <p className="error-message">{error.errorMessage}</p>}
    </div>
  )
}

function getImg(name, images, pic){
  if (name === "upperstl" || name === "lowerstl"){
    return <img src={pic} style={{width: 300, height: 300}} />;
  } else if (images[name]){
    return <img 
      src={URL.createObjectURL(images[name])} 
      className="dropzone-inner" 
      style={{width: 300, height: 300, objectFit: "contain"}} />;
  } else{
    return <img src={pic} style={{width: 300, height: 300}} />;
  }
}


import React, {useState, useEffect, useRef} from "react";

import {useParams, useNavigate} from "react-router-dom";
import {
  ListGroup, 
  Badge, 
  Form, 
  Button, 
  Spinner,
  Card,
  Table,
  Row,
  Col
} from "react-bootstrap";
import {AiOutlineExclamationCircle, AiOutlinePlusCircle, AiFillExclamationCircle} from "react-icons/ai";
import {BsExclamationCircle} from "react-icons/bs";

import UploadForm from "../components/Form/UploadForm";
import XrayModal from "../components/Modals/XrayModal";
import UpperModal from "../components/Modals/UpperModal";
import LowerModal from "../components/Modals/LowerModal";
import PanModal from "../components/Modals/PanModal";

import {setFlag, getFlag} from "../helper/flagHelper";

function Case(){
  const {case_id} = useParams();
  const [id, setId] = useState(case_id);
  const [type, setType] = useState("");
  const navigate = useNavigate();

  const [agreement, setAgreement] = useState(false);
  const [agreementError, setAgreementError] = useState(false);

  //return <Spinner className="spinner" animation="border" />;

  function mouseOver(name){
    setType(name);
  }

  function mouseOut(){
    setType("");
  }

  function agreeAndGenerate(){
    if (agreement){
      navigate(`/treatment-plan/${case_id}`)
    } else {
      setAgreementError(true);
    }
  }

  useEffect(() => {
    setId(case_id);
    
    if (getFlag() !== "case"){
      setFlag("case");
      window.location.reload();
    }
  }, [])

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-home">
              {/* logo */}
              {/* <Card.Header>
                <div className="brand-logo" style={{padding: "2px 5px"}}>
                  <span className="rabie">RABIE</span><span className="ai">AI</span>
                </div>
              </Card.Header> */}
              <Card.Body style={{overflow: "hidden"}}>
                <Card className="patient-record-card">
                  <ListGroup variant="flush" className="patient-record-title">
                    <ListGroup.Item><h3 className="patient-record-heading">X-Rays And Scans.</h3></ListGroup.Item>
                  </ListGroup>

                  <div className="case-images-container">
                    <UpperModal caseId={case_id}/>
                    <LowerModal caseId={case_id}/>
                    <XrayModal caseId={case_id}/>
                    <PanModal caseId={case_id}/>
                  </div>

                  <div className="case-agreement">
                  <strong>IMPORTANT:
                  <ul>
                    <li>To ensure accuracy of AI generated treatment plan, review and adjust plotted points as needed.</li>
                    <li>For Class II {'>'} 3mm, Class III and cases with severely displaced canines, submit the case to CloudOrtho.</li>
                  </ul>
                  </strong>

                    {/* <h4>Agree to terms</h4> */}
                    <div className="d-flex">
                      <Form.Check className="me-2" onChange={() => {
                        setAgreementError(false);
                        setAgreement(!agreement)
                      }}/>
                      <div>
                        The user acknowledges and understands that any Class II {'>'} 3mm, Class II Div 2, Class III, cases with severely displaced canines, expansion {'>'} 10mm and extraction protocols need to be submit directly to CloudOrtho for further assessment. By accepting and generating treatment plan, the user agrees that all medical and dental liability, including, but not limited to, the diagnosis and treatment plan generated, fall upon the user. The user understands that CloudOrtho and its RABIEAI is not responsible for any treatment carried out by the user based on RABIEAI's results
                        <br/>
                        <a href="https://www.cloudortho.com/terms-conditions/" target="_blank">(Terms & Conditions)</a>
                      </div>
                    </div> 
                    {agreementError && <div className="d-flex mt-4" style={{color: "red"}}>
                      <BsExclamationCircle color="red" size={22} className="me-2"/>
                      <div>
                        You need to agree to the RABIEAI user agreement
                      </div>
                    </div> }
                  </div>

                  <Button 
                    variant="info" 
                    className="m-3" 
                    onClick={agreeAndGenerate}
                  >Accept and generate treatment plan</Button>
                </Card>

              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Case;

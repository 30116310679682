
import React, {useState, useEffect, useContext, createRef, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {Accordion, AccordionContext, Spinner, FloatingLabel, Form, Button, Alert} from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import {AiOutlineExclamationCircle, AiOutlinePlusCircle} from "react-icons/ai";
import {SlArrowDown} from "react-icons/sl";
import ReactToPdf from "react-to-pdf";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import axios from "axios";

import Img from "./Image";
import {getUrl} from "../../helper/urlHelper";

const options = {
  orientation: 'portrait',
  unit: 'in',
};

let patientImages = [
  "frontalPhoto",
  "smilePhoto",
  "lateralPhoto",
  "rightIntraoral",
  "frontalIntraoral",
  "leftIntraoral",
  "upperOcclusal",
  "lowerOcclusal",
  "pan",
  "lateralCeph",
  "upperpng",
  "lowerpng"
];

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div
      onClick={decoratedOnClick}
    >
      {children}
    </div>
  );
}

export default function({case_id}){

  const [patientDetails, setPatientDetails] = useState({
    name: "",
    birthday: "",
    ethnicity: "",
    gender: ""
  })
  const [incisorAngulation, setIncisorAngulation] = useState([]);
  const [crowdingSpacing, setCrowdingSpacing] = useState([]);
  const [lowerArch, setLowerArch] = useState([]);
  const [upperArch, setUpperArch] = useState([]);
  const [specialRecommendation, setSpecialRecommendation] = useState("");

  const [showTextArea, setShowTextArea] = useState(false);
  const [recommendationsLoader, setRecommendationsLoader] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [info, setInfo] = useState({
    info: false,
    type: "success",
    message: ""
  })

  const ref = createRef();
  const pdfExportComponent = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${getUrl()}/getTreatmentPlan/${case_id}`)
    .then(function (response){
      setIncisorAngulation(response.data.incisorAngulation)
      setCrowdingSpacing(response.data.crowdingSpacing)
      setLowerArch(response.data.lowerTreatment)
      setUpperArch(response.data.upperTreatment)
      setSpecialRecommendation(response.data.specialRecommendation)
    })
    .catch(function (error) {
      console.log(error);
    });

    axios.get(`${getUrl()}/getPatientDetails/${case_id}`)
    .then(function (response) {
      console.log(response);
      setPatientDetails(response.data);
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(function(){
      setLoaded(true);
    });
  }, []);

  function toggleTextArea(){
    setInfo({
      info: false,
      type: "success",
      message: ""
    })
    setShowTextArea(!showTextArea);
  }

  function updateRecommendations(){
    setRecommendationsLoader(true);

    axios.post(`${getUrl()}/saveSpecialRecommendation`, {
      caseid: case_id, 
      specialRecommendation
    })
    .then(function (response){
      setRecommendationsLoader(false);
      setInfo({
        info: true,
        type: "success",
        message: "Special Recommendation updated successfully"
      })
    })
    .catch(function (error){
      setRecommendationsLoader(false);
      setInfo({
        info: true,
        type: "danger",
        message: error.message
      })
    });
  }

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  return (
    <Accordion defaultActiveKey="2" style={{padding: 0}}>
      <Card className="acc-class">
        <Card.Header className="">
          <ContextAwareToggle eventKey="0"><div className="me-auto d-flex">
            <div className="record-icon-container">
              <AiOutlineExclamationCircle size={25} color="white" className="record-icon"/>
            </div>
            <div>
              <div className="fw-bold"><h4 className="patient-record-heading">Patient Details</h4></div>
              <span>Required For Treatment</span>
            </div>
            <div className="ms-auto me-2 mt-2">
              <SlArrowDown size={25}/> 
            </div>
          </div></ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            {!loaded && <div style={{width: "100%", height: 100}}><Spinner className="spinner" animation="border" /></div>}
            {loaded && <div>
              <div className="accordion-diagnosis d-flex justify-content-between align-items-center">
                <div>
                  Patient Details
                </div>

                <div>
                  <span className="text-dark">Powered by</span>
                  <div className="brand-logo-small">
                    <span className="rabie-small">RABIE</span><span className="ai-small">AI</span>
                  </div>
                </div> 
              </div> 
              <p className="m-2">
                <span>Name: </span>
                {patientDetails.name}
              </p>
              <p className="m-2">
                <span>Date Of Birth: </span>
                {patientDetails.birthday}
              </p>
              <p className="m-2">
                <span>Gender: </span>
                {patientDetails.gender}
              </p>
              <p className="m-2">
                <span>Ethnicity: </span>
                {patientDetails.ethnicity}
              </p>
            </div>}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className="acc-class">
        <Card.Header className="">
          <ContextAwareToggle eventKey="1"><div className="me-auto d-flex">
            <div className="record-icon-container">
              <AiOutlineExclamationCircle size={25} color="white" className="record-icon"/>
            </div>
            <div>
              <div className="fw-bold"><h4 className="patient-record-heading">Patient Images, X-Rays and Scans</h4></div>
              <span>Required For Treatment</span>
            </div>
            <div className="ms-auto me-2 mt-2">
              <SlArrowDown size={25}/> 
            </div>
          </div></ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body className="patient-images-container">
            {patientImages.map((image, i) => (
              <Img key={i} url={image}/>
            ))}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className="acc-class tp"> 
        <Card.Header>
          <ContextAwareToggle eventKey="2"><div className="me-auto d-flex">
            <div className="record-icon-container">
              <AiOutlineExclamationCircle size={25} color="white" className="record-icon"/>
            </div>
            <div>
              <div className="fw-bold">
                <h4 className="patient-record-heading" style={{marginTop: 5}}>Diagnosis and treatment plans</h4>
              </div>
            </div>
            <div className="ms-auto me-2 mt-2">
              <SlArrowDown size={25}/> 
            </div>
          </div></ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            {!loaded && <div style={{width: "100%", height: 100}}><Spinner className="spinner" animation="border" /></div>}
            {loaded && <>
              <PDFExport ref={pdfExportComponent} keepTogether="p" paperSize="A4" margin={{ top: 30, left: 20, right: 20, bottom: 30 }} 
                  fileName="treatment-plan" author="RabieAI">
                <div className="accordion-diagnosis d-flex justify-content-between align-items-center">
                  <div>
                    Diagnosis
                  </div>

                  <div>
                    <span className="text-dark powered-by">Powered by</span>
                    <div className="brand-logo-small">
                      <span className="rabie-small">RABIE</span><span className="ai-small">AI</span>
                    </div>
                  </div> 
                </div> 
                <p className="mt-2 ms-3">
                  <p>Incisor Angulation:</p>
                  {incisorAngulation.map((v, i) => (
                    <p key={i}> - {v}</p>
                  ))}
                </p>
                <p className="mt-2 ms-3">
                  <p>Crowding / Spacing:</p>
                  {crowdingSpacing.map((v, i) => (
                    <p key={i}> - {v}</p>
                  ))}
                </p>
                <div className="accordion-diagnosis mt-4 d-flex justify-content-between align-items-center">
                  <div>
                    Treatment Plan
                  </div>
                </div>
                <p className="mt-2 ms-3">
                  <p>Lower Arch:</p>
                  {lowerArch.map((v, i) => (
                    <p key={i}>{i + 1}. {v}</p>
                  ))}
                </p>
                <div className="mt-2 ms-3">
                  <p>Upper Arch:</p>
                  {upperArch.map((v, i) => (
                    <p
                      key={i}
                      dangerouslySetInnerHTML={{
                        __html: `${i + 1}. ${v.replace(/\n/g, "<br>")}`,
                      }}
                    />
                  ))}
                </div>

                <div className="mt-3 ms-3">
                  <p>Special Recommendations:</p>
                  {showTextArea ? <Card
                    text={'dark'}
                    style={{ borderRadius: 0 }}
                    className="mb-2"
                  >
                    <Card.Body style={{padding: 10, width: "100%"}}>
                      <FloatingLabel controlId="floatingTextarea2" label="Special Recommendation">
                        <Form.Control
                          as="textarea"
                          placeholder="Special Recommendation"
                          style={{ height: "300px" }}
                          onChange={(e) => setSpecialRecommendation(e.target.value)}
                          defaultValue={specialRecommendation}
                        />
                      </FloatingLabel>
                      <Button variant="info" style={{position: "relative"}} onClick={updateRecommendations} disabled={recommendationsLoader}>
                        {recommendationsLoader ? <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        /> : <span>Save</span>}
                      </Button>
                      <Button variant="dark" onClick={toggleTextArea}>Close</Button>
                      {info.info && <div className={`bg-${info.type} d-inline-block ms-1 text-light recommendationAlert`}>
                        {info.message}
                      </div>}
                    </Card.Body>
                  </Card> : 
                  <div
                    style={{cursor: "pointer"}}
                    className="mb-2 shadow-none"
                  >
                    <div onClick={toggleTextArea} className="spectial-rec-container" style={{paddingLeft: 0}}>
                      <p className="specialRecommendation">{specialRecommendation}</p>
                    </div>
                  </div>}
                </div>

                <div className="disclaimer mt-3 ms-3">
                  <p>
                    IMPORTANT:
                    <br />
                    <small>
                      Submit your case to {' '}
                      <a href="https://www.cloudortho.com" target="_blank">CloudOrtho</a> for Tx Planning with a specialist orthodontist at no extra fees if your case has any of the following:
                      <br />
                      (1) Class II {'>'} 3mm, (2) Class II Div 2, (3) Class III, (4) Severely displaced canines, <br /> (5) Expansion {'>'} 10mm, (6) Extraction protocols, (7) Case involves fixed appliance. 
                      <br />
                      <a href="https://www.cloudortho.com" target="_blank">INSTRUCTION VIDEO FOR SUBMITTING TO CLOUDORTHO</a>
                      </small>
                  </p>
                </div>
                <div className="mt-3 ms-3">
                  <p>
                    Disclaimer
                    <br/>
                    <small>
                      CloudOrtho and RABIEAI provide only recommended diagnosis and treatment planning service based on the submitted clinical records.
                      <br/>
                      Clinicians bear the legal responsibility for approving the treatment plan and for performing the orthodontic treatment to the patient.
                      <br/>
                      RABIEAI v1.0 provides non-extraction protocols only. Results may vary.
                      <br/>
                      For any inquiries about AI generated treatment plan, please contact support@cloudortho.com
                    </small>
                  </p>
                </div>
              </PDFExport>
              
              <div className="treatment-plan-footer ms-3">
                <button onClick={exportPDFWithComponent}>Download as pdf</button>
                {/* <ReactToPdf targetRef={ref} filename="treatment-plan.pdf" options={options} x={.25} y={.25} scale={.8}>{({toPdf}) => (
                  <button onClick={toPdf}>Download as pdf</button>
                  )}
                </ReactToPdf> */}
              </div>
            </>}
            
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}


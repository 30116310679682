
import React, {useState, useRef, useEffect} from "react";
import { Stage, Layer, Star, Text, Circle, Image as CanvasImage, Line } from 'react-konva';

export default function({values, count, image, width, height, originalWidth, originalHeight}){

  const [lines, setLines] = useState(reduceLines());
  const [circles, setCircles] = useState(reduceCircles());

  function scaleWidth(w){
    return (w * width) / originalWidth;
  }

  function scaleHeight(h){
    return (h * height) / originalHeight;
  }

  function reduceCircles(){
    let obj = {};

    for (let i = 0; i < 14; i++){
      let key = "d" + (i + 1);

      obj[key] = [
        scaleWidth(values[key + "x"]),
        scaleHeight(values[key + "y"])
      ];
    }

    for (let i = 0; i < 14; i++){
      let key = "m" + (i + 1);

      obj[key] = [
        scaleWidth(values[key + "x"]),
        scaleHeight(values[key + "y"])
      ];
    }

    for (let i = 0; i < 3; i++){
      let key = "l" + (i + 4);

      obj[key] = [
        scaleWidth(values[key + "x"]),
        scaleHeight(values[key + "y"])
      ];
    }

    for (let i = 0; i < 3; i++){
      let key = "r" + (i + 4);

      obj[key] = [
        scaleWidth(values[key + "x"]),
        scaleHeight(values[key + "y"])
      ];
    }    

    obj["c"] = [
      scaleWidth(values["cx"]),
      scaleHeight(values["cy"])
    ]

    return obj
  }

  function reduceLines(){
    let obj = {};

    for (let i = 0; i < 14; i++){
      let key = "dm" + (i + 1);

      obj[key] = [
        scaleWidth(values["d" + (i + 1) + "x"]),
        scaleHeight(values["d" + (i + 1) + "y"]),
        scaleWidth(values["m" + (i + 1) + "x"]),
        scaleHeight(values["m" + (i + 1) + "y"])
      ]
    }

    return obj;
  }

  useEffect(() => {
    setLines(reduceLines());
    setCircles(reduceCircles());
  }, [count]);

  return (
    <Stage width={width} height={height} style={{zIndex: 5}}>
      <Layer>
        <CanvasImage image={image} width={width} height={height}/>
        {Object.keys(lines).map((line, i) => (
          <Line
            points={lines[line]}
            tension={0.2}
            stroke="red"
            width={0.2}
          />
        ))}
        {Object.keys(circles).map((circle, i) => (
          <>
            <Circle
              key={i}
              id={circle}
              x={circles[circle][0]}
              y={circles[circle][1]}
              radius={3}
              numPoints={5}
              innerRadius={20}
              outerRadius={40}
              fill="white"
              opacity={1}
              shadowColor="black"
              shadowBlur={10}
              shadowOpacity={0.6}
              onMouseOver={() => {}}
            />
          </>
        ))}
      </Layer>
    </Stage>
  )
}


import React, {useState} from "react";
import {Button, Card, Spinner, Toast} from "react-bootstrap";

export default function Confirmation({
  loading,
  setShow,
  setMessage,
  setErrorBg,
  errorBg,
  message,
  show,
  setCircles,
  setLines,
  setInitialValues,
  reduceLines, 
  reduceCircles,
  initialValues,
  updatePoints,
  setConfirm,
  onHide,
  setValues,
  modified,
  values,
  count,
  setCount
}){

  function closeConfirm(){
    if (modified){
      setInitialValues({...values});
      setCount(++count);
      onHide();
      setConfirm(false);
      setShow(false);
    } else {
      setConfirm(false);
      setLines(reduceLines(initialValues));
      setCircles(reduceCircles(initialValues));
      setValues({...initialValues});
      onHide();
      setConfirm(false);
      setCount(0);
      setShow(false);
    }
  }

  function discard(){

  }

  return (
    <div className="confirmation-container">
       <Card className="text-center">
        <Card.Title className="mt-3">Are you sure you want to exit without saving?</Card.Title>
        <Card.Body className="d-flex justify-content-center">
          {loading ? <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          /> : <Toast bg={errorBg} onClose={() => setShow(false)} show={show}>
            <Toast.Body>{message}</Toast.Body>
          </Toast>}
        </Card.Body>
        <Card.Footer className="text-muted">
          <Button variant="info" className="ms-2 mb-2" onClick={updatePoints} disabled={loading}>
            {loading ? <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            /> : <span>Save</span>}
          </Button>
          <Button variant="danger" className="ms-2 mb-2" onClick={closeConfirm}>Close</Button>
        </Card.Footer>
      </Card>
    </div>
  )
}
